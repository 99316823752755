import axios from "axios";
import { collection, doc, query, where, getDocs, updateDoc, limit } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../db/banco";
import ILinkGeradoISM from "../../types/ILinkGeradoISM";
import Dot from "../../assets/Dot";

export default function Principal() {
    const { idCampanhaParametro, idInfluenciadorParametro } = useParams<{
        idCampanhaParametro: string;
        idInfluenciadorParametro: string;
    }>(); // pega os parâmetros do link

    const [idBD, setIdBD] = useState("");
    const useCollectionRefLinkISM = collection(db, "linkGeradoISM"); // tabela escolhida: link gerado ISM
    
    // useEffect que inclui o uso de cache
    useEffect(() => {        
        if (idCampanhaParametro) {
            const cachedLink = localStorage.getItem(`${idCampanhaParametro}-${idInfluenciadorParametro}`);
            if (cachedLink && !cachedLink.endsWith('subaccount')) {
                // Redireciona imediatamente se o link estiver em cache                
                window.location.href = cachedLink;
                // alert(cachedLink)
            } else {
                // Busca no Firestore caso não tenha cache
                obterLinkGeradoISM();
            }
        }
    }, [idCampanhaParametro]);

    // useEffect(() => {        
    //     obterLinkGeradoISM();
    // }, [idCampanhaParametro]);

    // Função que busca o link gerado no Firestore
    const obterLinkGeradoISM = async () => {
        const idInfluenciadorParametroConvertido = Number(idInfluenciadorParametro);

        // Query otimizada com limit(1)
        const q = query(
            useCollectionRefLinkISM,
            where("codigoDaCampanha", "==", idCampanhaParametro),
            where("codigoDoInfluenciador", "==", idInfluenciadorParametroConvertido),
            limit(1)
        ); // busca o link campanha que tem id da campanha e id do influencior igual dos parâmetros

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const docData = querySnapshot.docs[0];
            const linkData = docData.data() as ILinkGeradoISM;
            setIdBD(docData.id);

            if (!linkData.linkActionpay) {
                await buscarLink(); // caso não tenha link
            } else {
                // Salva o link no cache e redireciona
                localStorage.setItem(`${idCampanhaParametro}-${idInfluenciadorParametro}`, linkData.linkActionpay);
                // alert(linkData.linkActionpay);
                window.location.href = linkData.linkActionpay;
            }
        }
    };

    // Função que busca o link na API externa
    const buscarLink = async () => {
        const formatoAPI = "json";
        try {
            const response = await axios.get(
                `https://us-central1-ism-teste.cloudfunctions.net/buscarLinks?offer=${idCampanhaParametro}&format=${formatoAPI}`
            );

            if (!response.data.error) {
                const link = response.data.result.links[0].url;
                const linkModificado = link.replace(/subaccount$/, idInfluenciadorParametro); 
                // alert(linkModificado)

                // Salva no cache
                localStorage.setItem(`${idCampanhaParametro}-${idInfluenciadorParametro}`, linkModificado);

                // Redireciona imediatamente
                window.location.href = linkModificado;

                // Salva no Firestore em segundo plano
                salvarLinkISM(linkModificado);
            } else {
                alert("Código errado, ou campanha não adicionada nos favoritos");
            }
        } catch (error) {
            console.error("Erro ao buscar dados da Actionpay:", error);
        }
    };

    // Função que salva o link no Firestore
    const salvarLinkISM = async (link: string) => {
        if (idBD) {
            const linkGeradoISMRef = doc(db, "linkGeradoISM", idBD);
            try {
                await updateDoc(linkGeradoISMRef, { linkActionpay: link });
            } catch (error) {
                console.error("Erro ao salvar o link no Firestore:", error);
            }
        }
    };

    return <Dot />;
}
